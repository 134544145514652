<script setup lang="ts">
import { CacheTagPrefix, useRedisCache } from '~/composables/useRedisCache'

const { addTags } = useRedisCache()

const route = useRoute()

definePageMeta({
  layout: 'storefront',
})

onServerPrefetch(() => {
  addTags([{ prefix: CacheTagPrefix.View, value: route?.path.replace(/\/+$/, '') }])
})
</script>

<template v-slot="props">
  <section>
    <SfBloomreachPage>
      <TopHeader />
      <br-component component="main" />
      <AppFooter />
    </SfBloomreachPage>
  </section>
</template>
